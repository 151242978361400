import React from 'react';
import { connect } from 'react-redux';

const Preview = ({ articles }) => {
    return articles ? (
        <section className="blog-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <span>Aktualności & Blog</span>
                    <h2>Najnowsze wpisy na blogu</h2>
                    <p>Porady ekspertów, aktualności z branży, najnowsze modele okularóœ i soczewek, nowe punkty obsługi klienta, lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div className="row">

                    {
                        articles.slice(0,3).map((art,key)=>{
                            const postDate = new Date(art.date);
                            const dateString = `${postDate.getDate()} - ${postDate.getMonth()+1} - ${postDate.getFullYear()}`;
                            return (
                                <div key={key} className="col-lg-4 col-md-6">
                                    <div className="single-blog-post">
                                        <div className="post-image">
                                            <a href="/"><img src={art.featured_image_src} alt="imertage" /></a>
                                        </div>

                                        <div className="post-content">
                                            <div className="post-meta">
                                                <ul>
                                                    <li>Autor: <a href="/">{ art.author_name }</a></li>
                                                    <li>{ dateString }</li>
                                                </ul>
                                            </div>

                                            <h3><a href="/" dangerouslySetInnerHTML={{__html: art.title.rendered}} ></a></h3>
                                            <p dangerouslySetInnerHTML={{__html: art.excerpt.rendered.substr(0,100)+'...' }}></p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }

                </div>
            </div>
        </section>
    ) : null;
};

const mapStateToProps = state => ({ articles: state.Articles.items });

export default connect(mapStateToProps)(Preview);
