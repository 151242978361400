import React, {useEffect, useState} from 'react';
import Base from 'src/App/Templates/Base'
import BaseSimple from 'src/App/Templates/BaseSimple'
import BlogPreview from 'src/App/Sections/Blog/Preview';
import getBuildings from 'src/Api/Wp/getBuilding';
import getExperts from 'src/Api/Wp/getExpert';
import {
    HomeSlides,
    MainServices,
    About,
    OurMission,
    FunFacts,
    Services,
    DoctorPreview,
    Appointment,
    Feedback,
    Faq,
    PartnerPreview,
} from 'src/App/Sections';
import ServiceResults from 'src/App/Sections/Services/Results';

const Home = props => {

    const [buildings,setBuildings] = useState([]);
    const [pending,setPending] = useState(false);
    const [currentPage,setCurrentPage] = useState(false);
    const [lastResult,setLastResult] = useState(false);
    const [lastPage,setLastPage] = useState(false);
    const perPage = 12;

    useEffect(()=>{
        getBuildings({
            onSuccess: (a,b,c)=>{
                setBuildings(a.data.map(s=>{ return {title:s.title.rendered,excerpt:'',link:`/gabinety/${s.slug}`}; }));
                setLastResult(a.headers['x-wp-total']);
                setLastPage(a.headers['x-wp-totalpages']);
            }
        });
    },[]);

    return (
        <BaseSimple>

            <ServiceResults
                title="Salony i Gabinety"
                description='Salony sprzedaży oraz gabinety specjalistów'
                postType="build"
            />

            { /*<HomeSlides /> */}

            { /*<MainServices /> */}

            { /*<About /> */}

            { /*<OurMission /> */}

            { /*<FunFacts /> */}

            { /*<Services /> */}

            { /*<DoctorPreview /> */}

            { /*<Appointment /> */}

            { /*<Feedback /> */}

            { /*<Faq /> */}

            { /*<PartnerPreview /> */}

            { /*<BlogPreview /> */}

        </BaseSimple>
    );
};

export default Home;
