import React from 'react';
import './Listing.scss';
import Pagination from '../Pagination';
import {Spinner} from 'react-bootstrap';

const Listing = ({
    page,
    pagesCount,
    children,
    setPage,
    pending,
    ...props
}) => {
    return (
        <div>

            { pending ? (
            <div style={{
                display: 'block',
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                width: '100%',
                height: '100%',
                zIndex: '100',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                background: 'rgba(0,0,0,.3)',
                }}>
                <Spinner animation="border" variant="primary" />
            </div>
            ) : null }

            <div className="row">
                { children }
            </div>

            <div className="row">
                <div className="col col-xs-12">
                    <Pagination pagesCount={pagesCount} page={page} setPage={setPage} />
                </div>
            </div>

        </div>
    );
};

export default Listing;
