import * as Const from '../Constants/Expert';
import Reguest from 'src/Api/Wp/getExpert';

const setPending = () => ({ type: Const.PENDING });
const setError = () => ({ type: Const.FAIL });
const setSuccess = (items) => ({ type: Const.SAVE, items: items });

export const getData = (id = null) => (dispatch) => {
    dispatch(setPending());
    Reguest({
        onSuccess: res => {
            dispatch(setSuccess(res.data));
        },
        onFail: err => {
            dispatch(setError());
        },
        id: id
    });
};
