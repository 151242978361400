import React, {useState} from 'react';
import {Button,Form,Row,Col,Alert} from 'react-bootstrap';
import sendMail from 'src/Api/V1/sendMail';

const Switcher = ({
    options,
    onChange,
    value,
}) => {
    return (
        <div >
            {
                options.map((opt,key)=>{
                    return (
                        <div
                            onClick={()=>onChange(opt.value)}
                            key={key}
                            className='custom-control custom-switch'
                        >
                            <input
                                type='checkbox'
                                className='custom-control-input'
                                checked={opt.value === value ? true : false}
                                readOnly
                            />
                            <label className='custom-control-label'>
                                {opt.label}
                            </label>
                        </div>
                    );
                })
            }
        </div>
    );
};

const Modal = ({children}) => {
    return (<div className="div">{children}</div>);
};
Modal.Title = ({children}) => {
    return (<h1 className="div">{children}</h1>);
};
Modal.Header = ({children}) => {
    return (<div className="div">{children}</div>);
};
Modal.Body = ({children}) => {
    return (<div className="div">{children}</div>);
};
Modal.Footer = ({children}) => {
    return (<div className="div">{children}</div>);
};

const ModalForm = ({ handleClose }) => {

    const [postType,setPostType] = useState(''); //expert / building / both
    const [email,setEmail] = useState('');

    const [ buildType, setBuildType ] = useState(''); // shop / office /both
    const [ buildName, setBuildName ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ specjal, setSpecjal ] = useState('');
    const [ name, setName ] = useState('');
    const [ officeAddress, setOfficeAddress ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ city, setCity ] = useState('');
    const [ contactPhone, setContactPhone ] = useState('');
    const [ contactEmail, setContactEmail ] = useState('');
    const [ contactWebsite, setContactWebsite ] = useState('');

    const [rules,setRules] = useState([
        { checked: false, label: 'Utwórz konto i zapisz podane dane w moim koncie' },
        { checked: false, label: 'Akceptuję regulamin serwisu' },
        { checked: false, label: 'Zapisz do newslettera. Oznacza zgodę na otrzymywanie informacji marketingowych' }
    ]);

    const controlProps = {
        as: Col,
        xs:"12",
        sm:"6",
        md:"4",
        style: {
            textAlign: 'left',
            margin: '20px 0 20px',
            padding: '5px',
        }
    };

    const SingleControl = ({ label, value, onChange }) => {
        return (
            <Form.Group {...controlProps} >
                <Form.Label>{label}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={label}
                    value={value}
                    onChange={(e)=>onChange(e.target.value)}
                />
            </Form.Group>
        );
    };

    const [ pending, setPending ] = useState(false);
    const [ notification, setNotification ] = useState(null);
    const [ error, setError ] = useState(null);

    const sendHandler = () => {
        const values = {
            postType: postType,
            email:email,
            buildType:buildType,
            buildName:buildName,
            address:address,
            specjal:specjal,
            name:name,
            officeAddress:officeAddress,
            description:description,
            city:city,
            contactPhone:contactPhone,
            contactEmail:contactEmail,
            contactWebsite:contactWebsite,
            rules:rules,
        };
        setError(false);
        setNotification(false);
        setPending(true);
        sendMail({
            onSuccess: (r) => {
                setPending(false);
                setNotification("Przesłano formularz");
            },
            onFail: (r) => {
                setPending(false);
                setError("Wystąpił błąd podczas przesyłania formularza. Spróbuj ponownie lub wyślij email na adres kontakt@lokalnyoptyk.pl.");
            },
            message: values
        });
    };

    return (
        <>
            <Modal.Header>
                <Modal.Title>
                    Formularz Rejestracji
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <p style={{ fontSize: '18px', margin: '30px 0 30px' }}>
                    Uzupełnij informacje o dodawanym wpisie.
                    Przesłane dane zostaną najpierw przez nas zweryfikowane
                    a następnie dodane do bazy.
                </p>

                <Form as={Row}>

                    <Form.Group as={Col} {...controlProps} >
                        <Form.Label>Wybierz co chcesz dodać</Form.Label>
                        <Row>
                            <Switcher
                                value={postType}
                                onChange={setPostType}
                                options={[
                                    { value:'expert', label: 'Osobę - specjalistę, okulistę, optyka', },
                                    { value:'building', label: 'Miejsce - gabinet, salon sprzedaży', },
                                    { value:'both', label: 'Specjalistę oraz jego gabinet / salon' }
                                ]}
                            />
                        </Row>
                    </Form.Group>

                    <Form.Group {...controlProps}>
                        <Form.Label>Twój Adres Email</Form.Label>
                        <Form.Control type="email" placeholder="Twój email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                        <Form.Text className="text-muted">
                            Prześlemy ci potwierdzenie dodania dancyh gdy je zweryfikujemy.<br />
                            Podanie emaila oznacza zgodę na przesłanie tej informacji oraz akceptację regulaminu serwisu.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} xs="12" xm="6" md="4" >
                        {
                            rules.map((rule,key)=>{
                                return (
                                    <Form.Check key={key}
                                    style={{ textAlign: 'left' }}
                                        type="checkbox"
                                        label={rule.label}
                                        checked={rule.checked}
                                        onChange={()=>{
                                            const newRules = [ ...rules ];
                                            newRules[key].checked = !rule.checked;
                                            setRules(newRules)
                                        }}
                                    />
                                );
                            })
                        }
                    </Form.Group>

                    { postType === 'expert' || postType === 'both' ? (
                        <>
                            <SingleControl {...controlProps} value={name} label={"Imię i nazwisko"} onChange={setName} />
                            <SingleControl {...controlProps} value={specjal} label={"Specjalizacja"} onChange={setSpecjal} />
                            <SingleControl {...controlProps} value={officeAddress} label={"Adres"} onChange={setOfficeAddress} />
                        </>
                    ) : null }

                    { postType === 'building' || postType === 'both' ? (
                        <>
                            <Col {...controlProps} >
                                <p>Co znajduje się w tym punkcie?</p>
                                <Switcher  value={buildType} options={[
                                        { label: 'Salon - sprzedaż produktów', value:'shop' },
                                        { label: 'Gabinet - badania, zabiegi', value:'office' },
                                        { label: 'Salon i Gabinet', value:'both' }
                                    ]} onChange={setBuildType}
                                />
                            </Col>
                            <SingleControl {...controlProps} value={buildName} label={"Nazwa"} onChange={setBuildName} />
                            <SingleControl {...controlProps} value={address} label={"Adres - ulica, nr. budynku"} onChange={setAddress} />
                        </>
                    ) : null }

                    { postType ? (
                        <>
                            <SingleControl {...controlProps} value={description} label={"Opis"} onChange={setDescription} />
                            <SingleControl {...controlProps} value={city} label={"Miasto"} onChange={setCity} />
                            <SingleControl {...controlProps} value={contactPhone} label={"Telefon"} onChange={setContactPhone} />
                            <SingleControl {...controlProps} value={contactEmail} label={"Email"} onChange={setContactEmail} />
                            <SingleControl {...controlProps} value={contactWebsite} label={'Strona internetowa'} onChange={setContactWebsite} />
                        </>
                    ) : null }



                </Form>

            </Modal.Body>

            <Modal.Footer>

                { error ? (<Alert variant="danger">{error}</Alert>) : null }

                { notification ? (<Alert variant="success">{notification}</Alert>) : null }

                { pending ? ( <Alert variant="primary">Wysyłanie danych...</Alert>) : null }

                { !pending && !notification ? (
                    <Button
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            lineHeight: '33px',
                        }}
                        variant="primary"
                        onClick={sendHandler}
                    >
                        Wyślij
                        <i className="fas fa-plus"
                            style={{
                            display: 'block',
                            left: '0',
                            right: '0',
                            top: '0',
                            lineHeight: '33px',
                            transform: 'none',
                            marginLeft: '10px',
                            position: 'relative',
                            backgroundColor: '#19ce67' }}
                        ></i>
                    </Button>
                ) : null }

            </Modal.Footer>

        </>
    );
};

export default ModalForm;
