import { combineReducers } from "redux";
import Articles from "./Articles";
import Building from "./Building";
import Expert from "./Expert";
import Product from "./Product";

const RootReducer = combineReducers({
    Articles,
    Building,
    Expert,
    Product,
});

export default RootReducer;
