import React from 'react';
import NewsletterForm from 'src/App/Components/Form/Newsletter';

const FooterArea = () => {
    return (
        <section className="footer-area">
            <div className="container">
                <div className="subscribe-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="newsletter-content">
                                <h2>Newsletter</h2>
                                <p style={{ margin: 'auto' }}>Otrzymasz powiadomienia o nowych artykułach, zarejestrowanych przychodniach, nowościach oraz promocjach.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <NewsletterForm />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="logo">
                                <a href="/"><img src="/logo-negative.png" alt="logo-negative" style={{ maxWidth: '120px', width: '100%' }} /></a>
                                <p></p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <ul className="departments-list">
                                <li><a href="/">Gabinety i Salony sprzedaży</a></li>
                                <li><a href="/">Specjaliści, Optycy, Okuliści</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <p><i className="far fa-copyright"></i>  <a href="http://lokalnyoptyk.pl" rel="noopener noreferrer" target="_blank">LokalnyOptyk.pl</a></p>
                </div>
            </div>
        </section>
    );
};

export default FooterArea;
