import React, {useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import RegisterForm from './RegisterForm';
import {Link} from 'react-router-dom';

const areaStyle = {
    paddingTop: '20px',
    paddingBottom: '20px',
    width: '100%'
};

const gridStyle = {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '-10px 0 -10px'

};

const ModalForm = ({ show, handleClose }) => {

    return (
        <Modal show={show} onHide={handleClose} size="lg" >
            <RegisterForm handleClose={handleClose} />
        </Modal>
    );
};

const NavbarArea = () => {

    const [modal,setModal] = useState(false);

    return (
        <>
            <ModalForm show={modal} handleClose={()=>setModal(false)} />

            <div className="navbar-area" style={areaStyle} >
                <div className="container">
                    <div style={gridStyle}>
                        <div className="logo" style={{ margin: '10px' }}>
                            <a href="/">
                                <img src="/logo.png" alt="logo" style={{ maxWidth: '140px', width: '100%' }} />
                            </a>
                        </div>
                        <div style={{ margin: '10px' }}>
                        <Link to="/rejestracja" className="btn btn-primary">Rejestracja</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NavbarArea;
