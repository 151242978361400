import React from 'react';
import './Pagination.scss';
import {Pagination} from 'react-bootstrap';

const Pag = ({
    pagesCount,
    page,
    setPage,
    ...props
}) => {

    const prePages = Array( pagesCount > 1 && page > 1 ? page : 0 );
    const postPages = Array( pagesCount > 1 && page < pagesCount ? pagesCount - page : 0 );

    return (
        <div style={{ margin: '20px auto 20px' }}>

            <Pagination style={{ justifyContent: 'center' }} >

                { pagesCount > 1 && page > 1 ? (<>
                    <Pagination.First onClick={()=>setPage(1)} />
                    <Pagination.Prev onClick={()=>setPage( page > 1 ? page - 1 : 1 )} />
                </>) : null }

{ /*


                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Ellipsis />

                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item>
                <Pagination.Item active>{12}</Pagination.Item>
                <Pagination.Item>{13}</Pagination.Item>
                <Pagination.Item disabled>{14}</Pagination.Item>

                <Pagination.Ellipsis />
                <Pagination.Item>{20}</Pagination.Item>

*/}

<Pagination.Item active>{page} z {pagesCount}</Pagination.Item>

                { pagesCount > 1 && page < pagesCount ? (<>
                    <Pagination.Next onClick={()=>setPage( page < pagesCount ? page + 1 : pagesCount )} />
                    <Pagination.Last onClick={()=>setPage(pagesCount)} />
                </>) : null }

            </Pagination>

        </div>
    );
};

export default Pag;
