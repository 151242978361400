import React from 'react';
import './Page.scss';

const Header = props => <div className="Page__header" {...props} />;
const Body = props => <div className="Page__body" {...props} />;
const Footer = props => <div className="Page__footer" {...props} />;

const Page = props => <div className="Page" {...props} />;

Page.Header = Header;
Page.Body = Body;
Page.Footer = Footer;

export default Page;
