import React from 'react';
import Page from 'src/Components/Page';
import {SimpleFooter, SimpleHeader} from 'src/App/Sections/Temporary';

const Base = ({children}) => {
    return (
        <Page>
            <Page.Header>
                <SimpleHeader />
            </Page.Header>
            <Page.Body>
                { children }
            </Page.Body>
            <Page.Footer>
                <SimpleFooter />
            </Page.Footer>
        </Page>
    );
};

export default Base;
