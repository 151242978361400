import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Pages from './Pages';
import './App.css';
import { connect } from 'react-redux';
import ScrollToTop from 'react-router-scroll-top'
import {getData as getArticles} from 'src/Store/Actions/Articles';
import {getData as getBuilding} from 'src/Store/Actions/Building';
import {getData as getExpert} from 'src/Store/Actions/Expert';
import {getData as getProduct} from 'src/Store/Actions/Product';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = ({
  store,
  getArticles,
  getBuilding,
  getExpert,
  getProduct,
}) => {

  useEffect(
    ()=>{
     // getArticles();
     // getBuilding();
     // getExpert();
     // getProduct();
    },
    [
      getArticles,
      getBuilding,
      getExpert,
      getProduct
    ]
  );

  return (
    <div className="App">
      <Router basename={process.env.PRE_PATH} >
        <ScrollToTop>
          <Switch>

            {/* static pages */}
            <Route exact path="/" component={Pages.Page.Home} />
            <Route exact path="/onas" component={Pages.Page.About} />
            <Route exact path="/kontakt" component={Pages.Page.Contact} />
            <Route exact path="/rejestracja" component={Pages.Page.Register} />
            <Route exact path="/regulamin" component={Pages.Page.Rules} />
            <Route exact path="/mapa" component={Pages.Page.Map} />
            <Route exact path="/wyszukiwanie" component={Pages.Listing.Search} />

            {/* listing results */}
            <Route exact path="/blog" component={Pages.Listing.Blog} />
            <Route exact path="/produkty" component={Pages.Listing.Product} />
            <Route exact path="/salony" component={Pages.Listing.Shop} />
            <Route exact path="/gabinety" component={Pages.Listing.Office} />
            <Route exact path="/specjalisci" component={Pages.Listing.Doctor} />

            {/* single post */}
            <Route exact path="/blog/:slug" component={Pages.Single.Blog} />
            <Route exact path="/produkty/:slug" component={Pages.Single.Product} />
            <Route exact path="/salony/:slug" component={Pages.Single.Shop} />
            <Route exact path="/gabinety/:slug" component={Pages.Single.Office} />
            <Route exact path="/specjalisci/:slug" component={Pages.Single.Doctor} />

            {/* no match */}
            <Route component={Pages.Page.NoMatch} />

          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
}

const mapStateToProps = state => ({ store: state });

const mapDispatchToProps = (dispatch) => ({
  getArticles: (data) => dispatch(getArticles(data)),
  getBuilding: (data) => dispatch(getBuilding(data)),
  getExpert: (data) => dispatch(getExpert(data)),
  getProduct: (data) => dispatch(getProduct(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
