import Initial from '../InitialState/Expert';
import * as Const from '../Constants/Expert';

const Reducer = (state = Initial, action) => {
    switch (action.type) {
        case Const.PENDING:
            return {
                ...state,
                pending: true,
                items: [],
            }
        case Const.FAIL:
            return {
                ...state,
                pending: false,
                items: [],
            }
        case Const.SAVE:
            return {
                ...state,
                pending: false,
                items: action.items,
            }
        default:
            return state
    }
};

export default Reducer;
