import React from 'react';

const Main = () => {
    return (
        <div>
            <h3>Brak wyników.</h3>
        </div>
    );
};

export default Main;
