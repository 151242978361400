import React from 'react';
import BaseSimple from 'src/App/Templates/BaseSimple'
import RegisterForm from 'src/App/Components/Form/Register';
import {Container,Row,Col} from 'react-bootstrap';

const Home = props => {
    return (
        <BaseSimple>

            <div style={{ margin: '50px auto 50px' }}>
                <Container>
                    <RegisterForm />
                </Container>
            </div>

        </BaseSimple>
    );
};

export default Home;
