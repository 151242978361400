import axios from './axios';

const Request = async ({
    onSuccess = r=>r,
    onFail = e=>e,
    slug = null,
    perPage = null,
    page = null,
    search = null,
}) => {
    let params = []
    if(perPage){ params.push(`per_page=${perPage}`); };
    if(page){ params.push(`page=${page}`); };
    if(search){
        params.push(`search=${search}`);

        //params.push(`meta_key=address_city`);
        //params.push(`meta_value=Skiernie`);
        //params.push(`meta_query=OR`);

    };
    if(slug){ params.push('slug='+slug); };
    let paramsString = params ? params.filter(par=>par).join('&') : null;
    let afterUrl = '';

    if(paramsString){ afterUrl += `?${paramsString}`; };
    return await axios({
        method: 'get',
        url: `/build${afterUrl}`,
    })
    .then(onSuccess)
    .catch(onFail)
};

export default Request;
