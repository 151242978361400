import React, {useEffect, useState} from 'react';
import getArticles from 'src/Api/Wp/getArticles';
import {Empty, Loader} from '../../Components/Main';
import Base from 'src/App/Templates/Base';

const Page = ({
  match,
  ...props
}) => {

    const [pending,setPending] = useState(false);
    const [article,setArticle] = useState(null);

    useEffect(()=>{
        if(match.params.slug){
            setPending(true);
            getArticles({
                slug: match.params.slug,
                onFail: ()=>setPending(false),
                onSuccess: r => {
                    if(r.data){ setArticle(r.data[0]); }
                    setPending(false);
                }
            });
        }
    }, [match.params.slug]);

    return (
        <Base>
            { pending ? (<Loader />) : (
                <>{ article ? (

                <div>



        <section className="page-title-area page-title-bg2">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Blog Details</h2>
                            <ul>
                                <li><a href="index.html">Home</a></li>
                                <li>Blog Details</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img src="assets/img/blog/3.jpg" alt="imdbage" />
                            </div>

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li><span>Posted On:</span> <a href="/">September 31, 2020</a></li>
                                        <li><span>Posted By:</span> <a href="/">John Anderson</a></li>
                                    </ul>
                                </div>

                                <h3>250+ Medical Tips We Just Had to Share</h3>

                                <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

                                <blockquote className="wp-block-quote">
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>

                                    <cite>Tom Cruise</cite>
                                </blockquote>

                                <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>

                                <ul className="wp-block-gallery columns-3">
                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src="assets/img/blog/9.jpg" alt="imfsdfage" />
                                        </figure>
                                    </li>

                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src="assets/img/blog/8.jpg" alt="imdfgfdage" />
                                        </figure>
                                    </li>

                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src="assets/img/blog/7.jpg" alt="imnvbnage" />
                                        </figure>
                                    </li>
                                </ul>

                                <h3>Four major elements that we offer:</h3>

                                <ul className="features-list">
                                    <li><i className="flaticon-check-mark"></i> Scientific Skills For getting a better result</li>
                                    <li><i className="flaticon-check-mark"></i> Communication Skills to getting in touch</li>
                                    <li><i className="flaticon-check-mark"></i> A Career Overview opportunity Available</li>
                                    <li><i className="flaticon-check-mark"></i> A good Work Environment For work</li>
                                </ul>

                                <h3>Setting the mood with incense</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

                                <h3>A cleansing hot shower or bath</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                            </div>

                            <div className="article-footer">
                                <div className="article-tags">
                                    <span><i className="fas fa-bookmark"></i></span>

                                    <a href="/">Fashion</a>,
                                    <a href="/">Games</a>,
                                    <a href="/">Travel</a>
                                </div>

                                <div className="article-share">
                                    <ul className="social">
                                        <li><span>Share:</span></li>
                                        <li><a href="/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="/" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="post-navigation">
                                <div className="navigation-links">
                                    <div className="nav-previous">
                                        <a href="/"><i className="flaticon-left-arrow"></i> Prev Post</a>
                                    </div>

                                    <div className="nav-next">
                                        <a href="/">Next Post <i className="flaticon-arrow-pointing-to-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="comments-area">
                                <h3 className="comments-title">2 Comments:</h3>

                                <ol className="comment-list">
                                    <li className="comment">
                                        <article className="comment-body">
                                            <footer className="comment-meta">
                                                <div className="comment-author vcard">
                                                    <img src="assets/img/author1.jpg" className="avatar" alt="imfghage" />
                                                    <b className="fn">John Jones</b>
                                                    <span className="says">says:</span>
                                                </div>

                                                <div className="comment-metadata">
                                                    <a href="/">
                                                        <time>April 24, 2019 at 10:59 am</time>
                                                    </a>
                                                </div>
                                            </footer>

                                            <div className="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>

                                            <div className="reply">
                                                <a href="/" className="comment-reply-link">Reply</a>
                                            </div>
                                        </article>

                                        <ol className="children">
                                            <li className="comment">
                                                <article className="comment-body">
                                                    <footer className="comment-meta">
                                                        <div className="comment-author vcard">
                                                            <img src="assets/img/author2.jpg" className="avatar" alt="imajhge" />
                                                            <b className="fn">Steven Smith</b>
                                                            <span className="says">says:</span>
                                                        </div>

                                                        <div className="comment-metadata">
                                                            <a href="/">
                                                                <time>April 24, 2019 at 10:59 am</time>
                                                            </a>
                                                        </div>
                                                    </footer>

                                                    <div className="comment-content">
                                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                    </div>

                                                    <div className="reply">
                                                        <a href="/" className="comment-reply-link">Reply</a>
                                                    </div>
                                                </article>
                                            </li>

                                            <ol className="children">
                                                <li className="comment">
                                                    <article className="comment-body">
                                                        <footer className="comment-meta">
                                                            <div className="comment-author vcard">
                                                                <img src="assets/img/author3.jpg" className="avatar" alt="imjhgage" />
                                                                <b className="fn">Sarah Taylor</b>
                                                                <span className="says">says:</span>
                                                            </div>

                                                            <div className="comment-metadata">
                                                                <a href="/">
                                                                    <time>April 24, 2019 at 10:59 am</time>
                                                                </a>
                                                            </div>
                                                        </footer>

                                                        <div className="comment-content">
                                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                        </div>

                                                        <div className="reply">
                                                            <a href="/" className="comment-reply-link">Reply</a>
                                                        </div>
                                                    </article>
                                                </li>
                                            </ol>
                                        </ol>
                                    </li>

                                    <li className="comment">
                                        <article className="comment-body">
                                            <footer className="comment-meta">
                                                <div className="comment-author vcard">
                                                    <img src="assets/img/author2.jpg" className="avatar" alt="imnfhage" />
                                                    <b className="fn">John Doe</b>
                                                    <span className="says">says:</span>
                                                </div>

                                                <div className="comment-metadata">
                                                    <a href="/">
                                                        <time>April 24, 2019 at 10:59 am</time>
                                                    </a>
                                                </div>
                                            </footer>

                                            <div className="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>

                                            <div className="reply">
                                                <a href="/" className="comment-reply-link">Reply</a>
                                            </div>
                                        </article>

                                        <ol className="children">
                                            <li className="comment">
                                                <article className="comment-body">
                                                    <footer className="comment-meta">
                                                        <div className="comment-author vcard">
                                                            <img src="assets/img/author4.jpg" className="avatar" alt="imgbage" />
                                                            <b className="fn">James Anderson</b>
                                                            <span className="says">says:</span>
                                                        </div>

                                                        <div className="comment-metadata">
                                                            <a href="/">
                                                                <time>April 24, 2019 at 10:59 am</time>
                                                            </a>
                                                        </div>
                                                    </footer>

                                                    <div className="comment-content">
                                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                    </div>

                                                    <div className="reply">
                                                        <a href="/" className="comment-reply-link">Reply</a>
                                                    </div>
                                                </article>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>

                                <div className="comment-respond">
                                    <h3 className="comment-reply-title">Leave a Reply</h3>

                                    <form className="comment-form">
                                        <p className="comment-notes">
                                            <span id="email-notes">Your email address will not be published.</span>
                                            Required fields are marked
                                            <span className="required">*</span>
                                        </p>
                                        <p className="comment-form-comment">
                                            <label>Comment</label>
                                            <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                                        </p>
                                        <p className="comment-form-author">
                                            <label>Name <span className="required">*</span></label>
                                            <input type="text" id="author" name="author" required="required" />
                                        </p>
                                        <p className="comment-form-email">
                                            <label>Email <span className="required">*</span></label>
                                            <input type="email" id="email" name="email" required="required" />
                                        </p>
                                        <p className="comment-form-url">
                                            <label>Website</label>
                                            <input type="url" id="url" name="url" />
                                        </p>
                                        <p className="comment-form-cookies-consent">
                                            <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent" />
                                            <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                        </p>
                                        <p className="form-submit">
                                            <input type="submit" name="submit" id="submit" className="submit" value="Post Comment" />
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <aside className="widget-area" id="secondary">
                            <section className="widget widget_search">
                                <form className="search-form">
                                    <label>
                                        <span className="screen-reader-text">Search for:</span>
                                        <input type="search" className="search-field" placeholder="Search..." />
                                    </label>
                                    <button type="submit"><i className="fas fa-search"></i></button>
                                </form>
                            </section>

                            <section className="widget widget_fovia_posts_thumb">
                                <h3 className="widget-title">Popular Posts</h3>

                                <article className="item">
                                    <a href="/" className="thumb">
                                        <span className="fullimage cover bg1" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <time datetime="2019-06-30">June 30, 2019</time>
                                        <h4 className="title usmall"><a href="/">How to change yourself for the better</a></h4>
                                    </div>

                                    <div className="clear"></div>
                                </article>

                                <article className="item">
                                    <a href="/" className="thumb">
                                        <span className="fullimage cover bg2" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <time datetime="2019-06-30">June 30, 2019</time>
                                        <h4 className="title usmall"><a href="/">10 Tactics for marketing your company</a></h4>
                                    </div>

                                    <div className="clear"></div>
                                </article>

                                <article className="item">
                                    <a href="/" className="thumb">
                                        <span className="fullimage cover bg3" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <time datetime="2019-06-30">June 30, 2019</time>
                                        <h4 className="title usmall"><a href="/">Google web ranking changing much</a></h4>
                                    </div>

                                    <div className="clear"></div>
                                </article>
                            </section>

                            <section className="widget widget_recent_comments">
                                <h3 className="widget-title">Recent Comments</h3>

                                <ul>
                                    <li>
                                        <span className="comment-author-link">
                                            <a href="/">A WordPress Commenter</a>
                                        </span>
                                        on
                                        <a href="/">Hello world!</a>
                                    </li>
                                    <li>
                                        <span className="comment-author-link">
                                            <a href="/">Fovia</a>
                                        </span>
                                        on
                                        <a href="/">Hello world!</a>
                                    </li>
                                    <li>
                                        <span className="comment-author-link">
                                            <a href="/">Wordpress</a>
                                        </span>
                                        on
                                        <a href="/">Hello world!</a>
                                    </li>
                                    <li>
                                        <span className="comment-author-link">
                                            <a href="/">A WordPress Commenter</a>
                                        </span>
                                        on
                                        <a href="/">Hello world!</a>
                                    </li>
                                    <li>
                                        <span className="comment-author-link">
                                            <a href="/">Fovia</a>
                                        </span>
                                        on
                                        <a href="/">Hello world!</a>
                                    </li>
                                </ul>
                            </section>

                            <section className="widget widget_archive">
                                <h3 className="widget-title">Archives</h3>

                                <ul>
                                    <li><a href="/">May 2019</a></li>
                                    <li><a href="/">April 2019</a></li>
                                    <li><a href="/">June 2019</a></li>
                                </ul>
                            </section>

                            <section className="widget widget_categories">
                                <h3 className="widget-title">Categories</h3>

                                <ul>
                                    <li><a href="/">Business</a></li>
                                    <li><a href="/">Privacy</a></li>
                                    <li><a href="/">Technology</a></li>
                                    <li><a href="/">Tips</a></li>
                                    <li><a href="/">Uncategorized</a></li>
                                </ul>
                            </section>

                            <section className="widget widget_meta">
                                <h3 className="widget-title">Meta</h3>

                                <ul>
                                    <li><a href="/">Log in</a></li>
                                    <li><a href="/">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                    <li><a href="/">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                    <li><a href="/">WordPress.org</a></li>
                                </ul>
                            </section>

                            <section className="widget widget_tag_cloud">
                                <h3 className="widget-title">Tags</h3>

                                <div className="tagcloud">
                                    <a href="/">Business <span className="tag-link-count"> (3)</span></a>
                                    <a href="/">Design <span className="tag-link-count"> (3)</span></a>
                                    <a href="/">IT <span className="tag-link-count"> (2)</span></a>
                                    <a href="/">Marketing <span className="tag-link-count"> (2)</span></a>
                                    <a href="/">Mobile <span className="tag-link-count"> (1)</span></a>
                                    <a href="/">Protect <span className="tag-link-count"> (1)</span></a>
                                    <a href="/">Startup <span className="tag-link-count"> (1)</span></a>
                                    <a href="/">Tips <span className="tag-link-count"> (2)</span></a>
                                </div>
                            </section>
                        </aside>
                    </div>
                </div>
            </div>
        </section>




                </div>

                ) : (<Empty />) }</>
            ) }
        </Base>
    );

}

export default Page;
