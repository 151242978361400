import React, {useState} from 'react';
import {Button,Row,Col,Alert} from 'react-bootstrap';
import sendMail from 'src/Api/V1/sendMail';


const ModalForm = ({ handleClose }) => {

    const [email,setEmail] = useState('');

    const [ pending, setPending ] = useState(false);
    const [ notification, setNotification ] = useState(null);
    const [ error, setError ] = useState(null);
    const sendHandler = () => {
        if(!email){
            setError("wypełnij wszystkie pola");
            return false;
        }
        const values = {
            form:'newsletter',
            email:email,
        };
        setError(false);
        setNotification(false);
        setPending(true);
        sendMail({
            onSuccess: (r) => {
                setPending(false);
                setNotification(`Zapisano maila ${email} do newslettera.`);
            },
            onFail: (r) => {
                setPending(false);
                setError("Wystąpił błąd podczas przesyłania formularza. Spróbuj ponownie lub wyślij email na adres kontakt@lokalnyoptyk.pl.");
            },
            message: values
        });
    };

    if(notification){
        return (<Alert variant="success">{notification}</Alert>);
    }

    return (
        <>
            <div className="newsletter-form" data-toggle="validator">
               <input
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                type="email"
                className="input-newsletter"
                placeholder="Enter your email"
                name="EMAIL"
                required
                autoComplete="off"
               />

                <button
                    type="submit"
                    disabled={pending ? true : false}
                    onClick={sendHandler}
                >
                    Zapisz
                    <i className="fas fa-paper-plane"></i>
                </button>

                <div id="validator-newsletter" className="form-result">
                    { error ? (<Alert variant="danger">{error}</Alert>) : null }
                    { notification ? (<Alert variant="success">{notification}</Alert>) : null }
                </div>
            </div>
        </>
    );
};

export default ModalForm;
