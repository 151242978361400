import React, {useEffect, useState} from 'react';
import Listing from '../../Components/Listing';
import getBuildings from 'src/Api/Wp/getBuilding';
import {Form,Button,Col,InputGroup} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const Services = ({
    title = '',
    tag = '',
    description = '',
    moreLink = '',
    postType = null,
    ...props
}) => {

    const [pending,setPending] = useState(false);
    const [items,setItems] = useState([]);
    const [pagesCount,setPagesCount] = useState(false);
    const [page,setPage] = useState(1);
    const [phrase,setPhrase] = useState('');

    const getItems = () => {
        setPending(true);
        getBuildings({
            onSuccess: r => {
                setPending(false);
                setPagesCount(r.headers['x-wp-totalpages']);
                if(r.headers['x-wp-totalpages'] < page){ setPage(r.headers['x-wp-totalpages']); }
                setItems(r.data.map(item=>{ return { ...item, link: `/gabinety/${item.slug}` }; }));
                //setItems(r.data.map(item=>{ return { title: 'title', excerpt: 'excerpt', link: '/' }; }));
            },
            onFail: ()=>setPending(false),
            page: page,
            perPage: 12,
            search: phrase,

        });
    };

    useEffect(()=>{
        getItems();
    },[page]);

    const Child = ({post}) => {
        if(!post){ return null };

        return (
            <div className="col-lg-4 col-md-6 col-sm-6">
                <Link to={post.link}>
                    <div className="single-services-box bg-1" style={{ backgroundImage: `url()` }}>
                        {/*<div className="icon">
                            <i className="flaticon-cancer"></i>
                        </div>*/}
                        <h3><span
                        dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                        />
                        </h3>
                        <p>{post.acf_fields.address_city} {post.acf_fields.address__street}</p>

                        {/*<a href="/" className="read-more-btn">Read more <i className="fas fa-arrow-right"></i></a>*/}
                    </div>
                </Link>
            </div>
        );
    };

    return (
        <section className="services-area ptb-100 bg-f4f9fd">
            <div className="container">
                <div className="section-title">
                    <span>{tag}</span>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
                <div className="row">
                    <Form as={Col} xs="12">
                        <Form.Group>
                            <InputGroup>
                                <Form.Control
                                    style={{ display: 'inline-block' }}
                                    type="text" value={phrase} onChange={(e)=>setPhrase(e.target.value) }
                                />
                                <InputGroup.Append>
                                    <Button
                                        style={{ display: 'inline-block' }}
                                        onClick={getItems}
                                    >
                                        Szukaj
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </div>
                <div className="row">

                    <Listing
                        page={page}
                        pagesCount={pagesCount}
                        pending={pending}
                        setPage={setPage}
                        children={items.map((item,key)=><Child post={item} key={key} />)}
                    />

                </div>
            </div>
        </section>
    );
};

export default Services;
