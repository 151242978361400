import axios from './axios';

const Request = async ({
    onSuccess = r=>r,
    onFail = e=>e,
    message = '',
}) => {
    return await axios({
        method: 'post',
        url: `/form`,
        data: {
            message: JSON.stringify(message)
        }
    })
    .then(onSuccess)
    .catch(onFail)
};

export default Request;
