import axios from './axios';

const Request = async ({
    onSuccess = r=>r,
    onFail = e=>e,
    id = null,
}) => {
    return await axios({
        method: 'get',
        url: `/model/${id ? id : ''}`,
    })
    .then(onSuccess)
    .catch(onFail)
};

export default Request;
