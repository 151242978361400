import React, {useEffect, useState} from 'react';
import BaseSimple from 'src/App/Templates/BaseSimple'
import getBuildings from 'src/Api/Wp/getBuilding';

const Home = ({
    match
}) => {

    const [item,setItem] = useState(null);
    const [pending,setPending] = useState(false);

    useEffect(()=>{
        getBuildings({
            slug: match.params.slug,
            onSuccess: (a)=>{
                setItem(a.data.length ? a.data[0] : null);
            }
        });
    },[match.params.slug]);

    console.log("ITE", item);

    if(!item){
        return (
            <BaseSimple>
                <p>Pobieram dane...</p>
            </BaseSimple>
        );
    }

    return (
        <BaseSimple>

        {/*<section className="page-title-area page-title-bg3">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-title-content">
                            <h2>Our Services</h2>
                            <ul>
                                <li><a href="index.html">Home</a></li>
                                <li>Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>*/}

        <section className="about-area ptb-100">
            <div className="container-fluid p-0">
                <div className="row m-0">
                    {/*<div className="col-lg-6 col-md-12 p-0">
                        <div className="about-image">
                            <img src="assets/img/about-img1.jpg" alt="image" />
                        </div>
                    </div>*/}

                    <div className="col-lg-6 col-md-12 p-0">
                        <div className="about-content">
                            <span dangerouslySetInnerHTML={{
                                __html: 'Salon / Gabinet'
                            }} />
                            <h2 dangerouslySetInnerHTML={{
                                __html: item.title.rendered
                            }} />
                            <div>
                                <ul>
                                {
                                    Object.values(item.acf_fields).map((it,key)=>{
                                        return it ? (
                                            <li key={key}>{it}</li>
                                        ) : null;
                                    })
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/*
        <section className="main-services-area ptb-100 pt-0">
            <div className="container">
                <div className="row">

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="main-services-box">
                            <div className="icon">
                                <i className="flaticon-cancer"></i>
                            </div>
                            <h3><a href="#">Cancer Services</a></h3>
                            <p>Lorem ipsum dolor sit amet consecte adipiscing elit sed do eiusincidunt.</p>
                        </div>
                    </div>


                </div>
            </div>
        </section>
        */}

        </BaseSimple>
    );
};

export default Home;
